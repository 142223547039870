var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex pa-2"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.workers,"label":"Created by","item-text":"fullname","item-value":"uuid","loading":_vm.loading,"outlined":"","clearable":"","dense":""},on:{"change":_vm.getHistorylogs},model:{value:(_vm.createdByUuid),callback:function ($$v) {_vm.createdByUuid=$$v},expression:"createdByUuid"}})],1),(!_vm.haveClient)?[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('filter-by-clients',{on:{"selectC":function($event){return _vm.setPatient($event)}}})],1)]:_vm._e(),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"5"}},[_c('v-row',[_c('v-col',[_c('ma-date-picker',{attrs:{"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',[_c('ma-date-picker',{attrs:{"label":"To","past":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1),(_vm.dateFrom || _vm.dateTo)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-4",attrs:{"small":"","color":"error","icon":""},on:{"click":_vm.cleanFilters}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,522219765)},[_c('span',[_vm._v("Clean Filters")])])]:_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-3",attrs:{"color":"primary","icon":""},on:{"click":_vm.getHistorylogs}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Reload")])])],2),_vm._v(" "+_vm._s(/* Interval indicator */)+" "),(_vm.dateFrom && _vm.dateTo)?[_c('v-layout',{staticClass:"px-4 pb-2",attrs:{"column":""}},[_c('v-layout',{staticClass:"text-body-1 text--secondary",attrs:{"column":""}},[_c('div',[_vm._v(" From: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDateShow(_vm.query.date1)))])]),_c('div',[_vm._v(" To: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDateShow(_vm.query.date2)))])])])],1)]:_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"options":_vm.options,"headers":_vm.headers,"items":_vm.historylogs,"single-expand":"","show-expand":"","item-key":"uuid","expanded":_vm.expanded,"server-items-length":_vm.totallogs,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client ? item.client.fullname : "-")+" ")]}},{key:"item.polizeStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold cursor-pointer",style:({ color: _vm.colorShow(item) })},[_vm._v(_vm._s(_vm.statusShow(item)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('div',{staticClass:"text-center mt-2 mb-3"},[_c('strong',[_vm._v("Before:")])]),_c('v-row',[(_vm.dataShow(item.before) != '-')?_vm._l((_vm.dataShow(item.before)),function(key,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.keyShow(key[0]))+": ")]),(key[0] == 'monthlyPay' || key[0] == 'anualIncome')?[_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.valueShow(key))))])]:(
                    key[0] == 'address' ||
                    key[0] == 'mailAddress' ||
                    key[0] == 'billingAddress'
                  )?_vm._l((_vm.dataShow(_vm.valueShow(key))),function(ad,y){return _c('div',{key:y},[_c('span',{staticClass:"\n                        text-decoration-underline\n                        font-italic font-weight-medium\n                      "},[_vm._v(_vm._s(_vm.keyShow(ad[0]))+": ")]),_c('span',[_vm._v(_vm._s(_vm.valueShow(ad)))])])}):[_c('span',[_vm._v(_vm._s(_vm.valueShow(key)))])]],2)}):[_vm._v(" No data ")]],2),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"text-center mt-2 mb-3"},[_c('strong',[_vm._v("Changes:")])]),_c('v-row',[(_vm.dataShow(item.changes) != '-')?_vm._l((_vm.dataShow(item.changes)),function(key,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.keyShow(key[0]))+": ")]),(key[0] == 'monthlyPay' || key[0] == 'anualIncome')?[_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.valueShow(key))))])]:(
                    key[0] == 'address' ||
                    key[0] == 'mailAddress' ||
                    key[0] == 'billingAddress'
                  )?_vm._l((_vm.dataShow(_vm.valueShow(key))),function(ad,y){return _c('div',{key:y},[_c('span',{staticClass:"\n                        text-decoration-underline\n                        font-italic font-weight-medium\n                      "},[_vm._v(_vm._s(_vm.keyShow(ad[0]))+": ")]),_c('span',[_vm._v(_vm._s(_vm.valueShow(ad)))])])}):[_c('span',[_vm._v(_vm._s(_vm.valueShow(key)))])]],2)}):[_vm._v(" No data ")]],2),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"text-center mt-2 mb-3"},[_c('strong',[_vm._v("After:")])]),_c('v-row',[(_vm.dataShow(item.after) != '-')?_vm._l((_vm.dataShow(item.after)),function(key,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.keyShow(key[0]))+": ")]),(key[0] == 'monthlyPay' || key[0] == 'anualIncome')?[_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.valueShow(key))))])]:(
                    key[0] == 'address' ||
                    key[0] == 'mailAddress' ||
                    key[0] == 'billingAddress'
                  )?_vm._l((_vm.dataShow(_vm.valueShow(key))),function(ad,y){return _c('div',{key:y},[_c('span',{staticClass:"\n                        text-decoration-underline\n                        font-italic font-weight-medium\n                      "},[_vm._v(_vm._s(_vm.keyShow(ad[0]))+": ")]),_c('span',[_vm._v(_vm._s(_vm.valueShow(ad)))])])}):[_c('span',[_vm._v(_vm._s(_vm.valueShow(key)))])]],2)}):[_vm._v(" No data ")]],2)],1)],1)]}},{key:"item.createdAt",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(
          item.name != null &&
          item.lastName != null &&
          item.paymentAmount == null
        )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.convertPatient(item)}}},on),[_vm._v(" mdi-account-convert")])]}}],null,true)},[_c('span',[_vm._v("Convert to Patient")])])]:_vm._e()]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }