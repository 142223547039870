var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex pa-2"},[_c('div',{staticClass:"col-sm-8"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ma-date-picker',{attrs:{"datepickerProps":{
              'no-title': true,
            },"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{staticClass:"ml-2"},[_c('ma-date-picker',{attrs:{"datepickerProps":{
              'no-title': true,
            },"label":"To","past":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1),(_vm.dateFrom != null || _vm.dateTo != null)?[_c('div',{staticClass:"col-sm-3"},[_c('v-btn',{staticClass:"my-2",attrs:{"icon":"","small":"","dark":"","color":"red"},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cancel ")])],1)],1)]:_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-2",attrs:{"color":"success","icon":"","loading":_vm.donwloading,"disabled":_vm.donwloading},on:{"click":_vm.exportLogs}},on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-2",attrs:{"color":"primary","icon":""},on:{"click":_vm.getClientsLogs}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Reload")])])],2),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"options":_vm.options,"headers":_vm.headers,"items":_vm.clientslogs,"server-items-length":_vm.totallogs,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dob",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDAte(item.dob))+" ")]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}},{key:"item.task",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFile(item.task))+" ")]}},{key:"item.taskType",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.task ? _vm.getType(item.task.taskType) : "")+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(
          item.name != null &&
          item.lastName != null &&
          item.paymentAmount == null
        )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.convertPatient(item)}}},on),[_vm._v(" mdi-account-convert")])]}}],null,true)},[_c('span',[_vm._v("Convert to Patient")])])]:_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }